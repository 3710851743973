import React, { useEffect, useState } from "react";
import styles from "./ExpandableMenu.module.scss";
import { ReactComponent as RightArrowIcon } from "../../icons/arrow-next.svg";
import { ReactComponent as DownArrowIcon } from "../../icons/down-arrow.svg";

const ExpandableMenu = ({
  treeData,
  onNodeClick,
  selectedPageId,
  scrollToTop,
}) => {
  // State to keep track of expanded nodes
  const [expanded, setExpanded] = useState({});

  // Find the path of nodes to the selected page
  const findNodePath = (nodes, targetPageId, path = []) => {
    for (const node of nodes) {
      if (node.page_id === targetPageId) {
        return [...path, node.page_id];
      }
      if (node.children?.length > 0) {
        const result = findNodePath(node.children, targetPageId, [
          ...path,
          node.page_id,
        ]);
        if (result) return result;
      }
    }
    return null;
  };

  // Expand all parent nodes of the selected page
  useEffect(() => {
    if (selectedPageId && treeData) {
      const pathToNode = findNodePath(treeData, selectedPageId);
      if (pathToNode) {
        const newExpanded = {};
        pathToNode.forEach((pageId) => {
          newExpanded[pageId] = true;
        });
        setExpanded(newExpanded);
      }
    }
  }, [selectedPageId, treeData]);

  const handleArrowClick = (pageId) => {
    const isExpanded = expanded[pageId];
    setExpanded((prev) => ({
      ...prev,
      [pageId]: !isExpanded, // Toggle the expansion state
    }));
  };

  const handlePageNameClick = (node) => {
    onNodeClick(node.page_id, node.page_name); // Fetch data for the clicked page
  };

  // const handleNodeClick = (node) => {
  //   const isExpanded = expanded[node.page_id];
  //
  //   // Always fetch data when a node is clicked, even if it's already selected
  //   onNodeClick(node.page_id, node.page_name);
  //
  //   // Toggle expansion only if the node is not already expanded
  //   if (isExpanded) {
  //     setExpanded((prev) => ({
  //       ...prev,
  //       [node.page_id]: false, // Collapse the node
  //     }));
  //   } else {
  //     setExpanded((prev) => ({
  //       ...prev,
  //       [node.page_id]: true, // Expand the node
  //     }));
  //   }
  // };

  // Recursive function to render the tree
  const renderTree = (node, level = 0) => {
    if (!node || !node.page_id) return null; // Skip invalid nodes

    const isExpanded = expanded[node.page_id];
    const isSelected = selectedPageId === node.page_id;

    return (
      <div key={node.page_id} style={{ marginLeft: level * 8 }}>
        <div className={`${styles.node} ${isSelected ? styles.selected : ""}`}>
          {node.children?.length > 0 && (
            <span
              className={styles.expandIcon}
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering the page name click
                handleArrowClick(node.page_id);
              }}
            >
              {isExpanded ? <DownArrowIcon /> : <RightArrowIcon />}
            </span>
          )}
          <span
            className={`${styles.pageName} ${
              isSelected ? styles.selected : ""
            }`}
            onClick={() => handlePageNameClick(node)}
          >
            {node.page_name}
          </span>
        </div>
        {isExpanded && node.children?.length > 0 && (
          <div className={styles.children}>
            {node.children.map((child) => renderTree(child, level + 1))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.tree}>
      {treeData?.map((node) => renderTree(node)) || "No data available"}
    </div>
  );
};

export default ExpandableMenu;
