import React from "react";
import styles from "./Breadcrumbs.module.scss";
import RightArrow from "../../icons/arrow-right.svg";
import classNames from "classnames";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { ReactComponent as QuestionMarkIcon } from "../../icons/circled-question-mark.svg";
import DefineIcon from "../../icons/define.svg";
import MeasureIcon from "../../icons/measure.svg";
import AnalyseIcon from "../../icons/analyse.svg";
import ImproveIcon from "../../icons/improve.svg";
import ControlIcon from "../../icons/control.svg";

const getDataFromPath = (pathname) => {
  if (pathname.includes("/audits")) {
    return { title: "Audits", category: "Measure" };
  }
  if (pathname.includes("/findings")) {
    return { title: "Findings", category: "Analyse" };
  }
  if (pathname.includes("/improvement-projects")) {
    return { title: "Improvement Projects", category: "Improve" };
  }
  return { title: "Page", category: "General" };
};

const Breadcrumbs = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orgId } = useParams();

  const { meModeActive } = useSelector((state) => state.filters);
  const derivedData = getDataFromPath(location.pathname);
  const breadcrumbData = data || derivedData;

  const showQuestionMark = [
    "/findings",
    "/improvement-projects",
    "/audits",
  ].some((path) => location.pathname.includes(path));

  const getBadgeClassName = (category) => {
    switch (category) {
      case "define":
        return styles.badgeDefine;
      case "analyse":
        return styles.badgeAnalyse;
      case "measure":
        return styles.badgeMeasure;
      case "improve":
        return styles.badgeImprove;
      case "control":
        return styles.badgeControl;
      default:
        return styles.badgeGeneral;
    }
  };

  const getCategoryIcon = (category) => {
    switch (category.toLowerCase()) {
      case "define":
        return DefineIcon;
      case "measure":
        return MeasureIcon;
      case "analyse":
        return AnalyseIcon;
      case "improve":
        return ImproveIcon;
      case "control":
        return ControlIcon;
      default:
        return null;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.breadcrumbs}>
        <div className={styles.home} onClick={() => navigate(`/${orgId}`)}>
          Home
        </div>
        <img src={RightArrow} alt="Arrow icon" className={styles.icon} />
        <div
          className={classNames(
            styles.badge,
            getBadgeClassName(breadcrumbData?.category?.toLowerCase()),
          )}
        >
          <img
            src={getCategoryIcon(breadcrumbData?.category)}
            alt={`${breadcrumbData?.category} icon`}
            className={styles.badgeIcon}
          />
          {breadcrumbData?.category || "General"}
        </div>
        <img src={RightArrow} alt="Arrow icon" className={styles.icon} />
        <div className={styles.title}>{breadcrumbData?.title}</div>
      </div>
      <div className={styles.meModeQuestionMark}>
        {meModeActive && showQuestionMark && (
          <>
            <QuestionMarkIcon
              alt="What is Me Mode?"
              data-tooltip-id="me-mode-tooltip"
              data-tooltip-content="Me Mode shows the data where you are mentioned (e.g., reporter, responsible, accountable, consulted, informed)."
            />
            <Tooltip
              id="me-mode-tooltip"
              place="left"
              className={styles.tooltip}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Breadcrumbs;
