import React, { useState } from "react";
import styles from "./FloatingBar.module.scss";
import Button from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../redux/modalStore";
import UserIcon from "../../icons/person-green.svg";
import ExportIcon from "../../icons/export.svg";
import AddNewFileIcon from "../../icons/add-file.svg";
import { toggleMeMode } from "../../redux/filterStore";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { useMediaQuery } from "@mui/material";

const FloatingBar = () => {
  const dispatch = useDispatch();
  const { orgId } = useParams();

  const [isExpanded, setIsExpanded] = useState(true);

  const meModeActive = useSelector((state) => state.filters.meModeActive);
  const modules = useSelector((state) => state.user.modules[orgId]);

  const handleOpenNewFindingModal = () => {
    dispatch(
      showModal({
        name: "new_finding_modal",
      }),
    );
  };

  const handleOpenExportModal = () => {
    dispatch(
      showModal({
        name: "export_modal",
      }),
    );
  };

  const handleToggleMeMode = () => {
    dispatch(toggleMeMode());
  };

  // If there are no modules present, don't render floating bar
  if (!modules || modules.length === 0) {
    return null;
  }

  return (
    <div className={styles.floatingBarContainer}>
      {isExpanded ? (
        <div className={classNames(styles.floatingBar, styles.expanded)}>
          <Button
            className={classNames(styles.primaryButton, styles.purple)}
            icon={ExportIcon}
            text="Export files"
            onClick={handleOpenExportModal}
          />
          <Button
            className={classNames(styles.primaryButton, styles.purple)}
            icon={AddNewFileIcon}
            text="Add New Finding"
            onClick={handleOpenNewFindingModal}
          />
          <Button
            className={styles.primaryButton}
            icon={UserIcon}
            text={meModeActive ? "Exit Me Mode" : "Go To Me Mode"}
            onClick={handleToggleMeMode}
          />
          <button
            className={styles.toggleButton}
            onClick={() => setIsExpanded(false)}
          >
            ✕
          </button>
        </div>
      ) : (
        <button
          className={classNames(styles.circleButton, styles.closed)}
          onClick={() => setIsExpanded(true)}
        >
          +
        </button>
      )}
    </div>
  );
};

export default FloatingBar;
