import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import styles from "./ManageAudits.module.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../components/Loader/PageLoader";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import AuditCard from "../../components/AuditCard/AuditCard";
import useFetchOrganizationData from "../../actions/useFetchOrganizationData";
import Input from "../../components/Input/Input";
import AscendingIcon from "../../icons/sort-ascending-dark.svg";
import DescendingIcon from "../../icons/sort-descending-dark.svg";
import Button from "../../components/Button/Button";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import Select from "../../components/Select/Select";
import classNames from "classnames";
import CollapsibleCard from "../../components/CollapsibleCard/CollapsibleCard";
import _ from "lodash";
import CascadingDropdown from "../../components/CascadingDropdown/CascadingDropdown";
import { ReactComponent as DeleteIcon } from "../../icons/delete.svg";
import QuestionTree from "../../components/QuestionSelect/QuestionTree";
import DateRangeFilter from "../../components/DateRangeFilter/DateFIlterRange";
import AuditCalendar from "../../layouts/AuditCalendar/AuditCalendar";
import { v4 as uuidv4 } from "uuid";
import axiosInstance from "../../utils/utils";
import { getOrganizationData } from "../../actions/organization";
import { hideOverlay, showOverlay } from "../../redux/overlayLoaderStore";
import Swal from "sweetalert2";
import ConfirmationPopup from "../../modals/ConfirmationPopup";
import WarningIllustration from "../../icons/no-data.svg";
import SmallConfirmationPopup from "../../modals/SmallConfirmationPopup/SmallConfirmationPopup";
import { generateDates } from "../../utils/helpers";
import { ReactComponent as ArchiveIcon } from "../../icons/archive-bold.svg";

const ManageAudits = () => {
  const { orgId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sessionToken = localStorage.getItem("session_token");

  const questionRefs = useRef({});
  const riskRefs = useRef({});
  const scheduleRef = useRef(null);
  const formikRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [customHtmlModule, setCustomHtmlModule] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortType, setSortType] = useState(null); // "name", "start", "end"
  const [sortDirection, setSortDirection] = useState("asc"); // "asc" or "desc"
  const [isAddingAudit, setIsAddingAudit] = useState(false);
  const [selectedAudit, setSelectedAudit] = useState(null);
  const [isEditingAudit, setIsEditingAudit] = useState(false);
  const [dateRangeWarning, setDateRangeWarning] = useState(null);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState(null);
  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false);
  const [showRiskDeleteWarning, setShowRiskDeleteWarning] = useState(false);
  const [riskToDelete, setRiskToDelete] = useState(null);
  const [showNewAuditConfirmation, setShowNewAuditConfirmation] =
    useState(false);

  const { auditsData, auditsTasksData, risksData } = useSelector(
    (state) => state.organization,
  );
  const modules = useSelector((state) => state.user.modules);

  useEffect(() => {
    return () => {
      setIsAddingAudit(false); // Close modal on unmount
    };
  }, []);

  // Extracting path from URL
  const lastIndex = location.pathname.lastIndexOf("/");
  const modifiedPath = location.pathname.substring(lastIndex);

  // Find the module object corresponding to the current path
  const currentModule = modules[orgId]?.find(
    (mod) => mod.path === modifiedPath,
  );

  const questionTypeOptions = [
    { label: "Text", value: "text" },
    { label: "File", value: "file" },
    { label: "Single Select", value: "single_select" },
    { label: "Multi Select", value: "multi_select" },
  ];

  useFetchOrganizationData();

  useEffect(() => {
    if (modules && modules[orgId]) {
      const module = modules[orgId].find(
        (module) => module.title === "Manage Audits",
      );
      if (module && module.module === "CustomHtml") {
        setCustomHtmlModule(module);
      }
      setLoading(false);
    }
  }, [modules, orgId]);

  if (loading) {
    return (
      <PageLoader pageLoader text="Loading, thank you for your patience..." />
    );
  }

  const auditsArray = auditsData ? Object.values(auditsData) : [];

  const safeObjectValues = (obj) => {
    return obj && typeof obj === "object" ? Object.values(obj) : [];
  };

  const getAuditDateRange = (auditUUID) => {
    const relatedTasks = safeObjectValues(auditsTasksData).filter(
      (task) => task.audit_uuid === auditUUID,
    );

    if (relatedTasks.length === 0) {
      return "No scheduled tasks";
    }

    const dates = relatedTasks
      .map((task) => new Date(task.date))
      .sort((a, b) => a - b);

    const formatDate = (date) => {
      return date.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "2-digit",
      });
    };

    const firstDate = formatDate(dates[0]);
    const lastDate = formatDate(dates[dates.length - 1]);

    return `${firstDate} - ${lastDate}`;
  };

  // Handle sorting button clicks
  const handleSort = (type) => {
    if (sortType === type) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortType(type);
      setSortDirection("asc"); // Reset to ascending when switching sort type
    }
  };

  const filteredAudits = auditsArray
    .filter((audit) => !audit.archived) // Exclude archived audits
    .filter((audit) =>
      audit.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );

  const sortedAudits = [...filteredAudits].sort((a, b) => {
    const getAuditDates = (auditUUID) => {
      const relatedTasks = Object.values(auditsTasksData).filter(
        (task) => task.audit_uuid === auditUUID,
      );

      if (relatedTasks.length === 0) {
        return null;
      }

      const dates = relatedTasks
        .map((task) => new Date(task.date))
        .sort((a, b) => a - b);

      return dates[dates.length - 1]; // Last scheduled date
    };

    let comparison = 0;

    if (sortType === "name") {
      comparison = a.name.localeCompare(b.name);
    } else if (sortType === "end") {
      const lastDateA = getAuditDates(a.uuid);
      const lastDateB = getAuditDates(b.uuid);

      if (lastDateA === null && lastDateB === null) return 0;
      if (lastDateA === null) return 1;
      if (lastDateB === null) return -1;

      comparison = lastDateA - lastDateB;
    } else if (sortType === "updated") {
      // Sorting by last updated
      const updatedA = new Date(a.updated_at);
      const updatedB = new Date(b.updated_at);

      comparison = updatedA - updatedB;
    }

    return sortDirection === "asc" ? comparison : -comparison;
  });

  const frequencyOptions = [
    { label: "Daily", value: "daily" },
    { label: "Weekly", value: "weekly" },
    { label: "Fortnightly", value: "fortnightly" },
    { label: "Monthly", value: "monthly" },
    { label: "Quarterly", value: "quarterly" },
    { label: "Half-Yearly", value: "half-yearly" },
    { label: "Yearly", value: "yearly" },
  ];

  const risksArray = Object.values(risksData);
  const sortedRisks = _.sortBy(risksArray, "risk_id");

  const chapterOptions = _.uniqBy(
    sortedRisks.map((risk) => ({
      id: risk.qms_id,
      label: `${risk.qms_id} ${risk.qms_description}`,
    })),
    "id",
  );

  const riskOptions = sortedRisks.map((risk) => ({
    id: risk.risk_num,
    reference: risk.risk_id,
    label: `${risk.risk_id} ${risk.description}`,
  }));

  const handleCloseAuditForm = (resetForm, values, initialValues) => {
    if (JSON.stringify(values) !== JSON.stringify(initialValues)) {
      setHasUnsavedChanges(true);
      setShowConfirmationPopup(true);
    } else {
      resetForm();
      setIsAddingAudit(false);
      setIsEditingAudit(false);
      setSelectedAudit(null);
      setDateRangeWarning(null);
    }
  };

  const handleNewAuditClick = () => {
    if (formikRef.current) {
      const { values, initialValues, resetForm } = formikRef.current;

      if (JSON.stringify(values) !== JSON.stringify(initialValues)) {
        setHasUnsavedChanges(true);
        setShowNewAuditConfirmation(true);
      } else {
        resetForm();
        setIsAddingAudit(true); // Ensure new audit form always opens
        setIsEditingAudit(false);
        setSelectedAudit(null);
      }
    } else {
      // Handle edge case where Formik hasn't initialized yet
      setIsAddingAudit(true);
      setIsEditingAudit(false);
      setSelectedAudit(null);
    }
  };

  const handleConfirmClose = (resetForm) => {
    resetForm();
    setIsAddingAudit(false);
    setIsEditingAudit(false);
    setSelectedAudit(null);
    setDateRangeWarning(null);
    setHasUnsavedChanges(false);
    setShowConfirmationPopup(false);
  };

  const handleCancel = () => {
    setShowConfirmationPopup(false);
  };

  // Function to add a new question to a specific risk
  const handleAddQuestion = (riskIndex, values, setFieldValue) => {
    const updatedRisks = [...values.risks];
    const newQuestionIndex = updatedRisks[riskIndex].questions.length;

    updatedRisks[riskIndex].questions.push({
      id: uuidv4(),
      type: "", // Default type is empty
    });
    setFieldValue("risks", updatedRisks);

    // Automatically scroll to newly added question
    setTimeout(() => {
      if (questionRefs.current[`${riskIndex}-${newQuestionIndex}`]) {
        questionRefs.current[`${riskIndex}-${newQuestionIndex}`].scrollIntoView(
          {
            behavior: "smooth",
            block: "center",
          },
        );
      }
    }, 100);
  };

  const formatAuditData = (values) => {
    // Ensure we only include risks that have questions with content
    const risksWithQuestions = values.risks.filter(
      (risk) => risk.risk && risk.questions && risk.questions.length > 0,
    );

    return {
      name: values.name,
      frequency:
        frequencyOptions.find((option) => option.label === values.frequency)
          ?.value || "", // Convert label to value
      form: risksWithQuestions.map((risk) => ({
        risk: risk.risk,
        questions: risk.questions.reduce((acc, question) => {
          // Skip invalid questions
          if (!question.type) return acc;

          const isUuid = (val) =>
            typeof val === "string" &&
            /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(
              val,
            );

          const questionId = isUuid(question?.id) ? question.id : uuidv4();

          if (
            question.type === "Single Select" ||
            question.type === "Multi Select"
          ) {
            if (
              !question.questionTree ||
              !question.questionTree.question ||
              !question.questionTree.options
            ) {
              return acc;
            }

            const cleanedOptions = (question.questionTree.options || []).filter(
              (option) => option.trim() !== "",
            );

            // Only add if there are options
            if (cleanedOptions.length > 0) {
              acc[questionId] = {
                question: question.questionTree.question,
                type:
                  question.type === "Single Select" ? "select" : "multiselect",
                options: cleanedOptions,
              };
            }
          } else if (question.type === "File") {
            // Skip if no text
            if (!question.text || question.text.trim() === "") return acc;

            acc[questionId] = {
              question: question.text,
              type: "file",
            };
          } else if (question.type === "Text") {
            // Skip if no text
            if (!question.text || question.text.trim() === "") return acc;

            acc[questionId] = {
              question: question.text,
              type: "text",
            };
          }

          return acc;
        }, {}),
      })),
    };
  };

  // Check if a date range overlaps with existing scheduled tasks
  const checkDateRangeOverlap = (startDate, endDate, auditUUID) => {
    if (!startDate || !endDate) return false;

    // Normalize dates to midnight for ensuring full-day comparison
    const start = new Date(startDate).setHours(0, 0, 0, 0);
    const end = new Date(endDate).setHours(23, 59, 59, 999);

    // Get existing tasks for this audit
    const existingTasks = Object.values(auditsTasksData).filter(
      (task) => task.audit_uuid === auditUUID,
    );

    for (const task of existingTasks) {
      const taskDate = new Date(task.date).setHours(12, 0, 0, 0); // Normalize task date

      // Check if taskDate falls within the selected range
      if (taskDate >= start && taskDate <= end) {
        return true; // Overlap found
      }
    }

    return false; // No overlap
  };

  const handleAuditClick = (audit) => {
    const relatedTasks = Object.values(auditsTasksData).filter(
      (task) => task.audit_uuid === audit.uuid,
    );

    // Extract start and end date from tasks
    const taskDates = relatedTasks
      .map((task) => new Date(task.date))
      .sort((a, b) => a - b);

    const startDate = taskDates.length > 0 ? taskDates[0] : null;
    const endDate =
      taskDates.length > 0 ? taskDates[taskDates.length - 1] : null;

    // Extract the responsible person (assuming all tasks have the same responsible person)
    const responsiblePerson =
      relatedTasks.length > 0 ? relatedTasks[0].responsible : "";

    setSelectedAudit({
      ...audit,
      startDate,
      endDate,
      responsible: responsiblePerson,
      tasks: relatedTasks,
    });

    setIsAddingAudit(false);
    setIsEditingAudit(true);
    setDateRangeWarning(null);
  };

  const formatAuditToFormik = (audit) => {
    const taskDates = audit.tasks
      ? audit.tasks.map((task) => new Date(task.date)).sort((a, b) => a - b)
      : [];

    const lastScheduledDate =
      taskDates.length > 0 ? taskDates[taskDates.length - 1] : null;

    // Set new start date to last scheduled date + 1 day
    const newStartDate = lastScheduledDate
      ? new Date(lastScheduledDate.getTime() + 86400000) // 86400000ms = 1 day
      : null;

    return {
      name: audit.name || "",
      frequency:
        frequencyOptions.find((f) => f.value === audit.frequency)?.label || "",
      risks: audit.form
        ? audit.form.map((riskItem, idx) => ({
            id: idx + 1,
            risk: riskItem.risk,
            questions: Object.entries(riskItem.questions || {}).map(
              ([id, question]) => ({
                id,
                type:
                  question.type === "multiselect"
                    ? "Multi Select"
                    : question.type === "select"
                      ? "Single Select"
                      : question.type === "file"
                        ? "File"
                        : "Text",
                text:
                  question.type === "text" || question.type === "file"
                    ? question.question
                    : "",
                questionTree:
                  question.type !== "text" && question.type !== "file"
                    ? {
                        question: question.question,
                        options: question.options,
                      }
                    : null,
              }),
            ),
          }))
        : [],
      startDate: newStartDate, // Use the latest scheduled date
      endDate: null, // Leave empty for user input
      responsible: audit.tasks?.length > 0 ? audit.tasks[0].responsible : "",
    };
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    dispatch(showOverlay());

    try {
      setSubmitting(true);

      // Create a deep copy of values to avoid modifying the original form data
      const processedValues = _.cloneDeep(values);

      // Check for invalid Select/MultiSelect questions before processing
      const invalidQuestions = [];

      processedValues.risks.forEach((risk, riskIndex) => {
        if (!risk.risk) return; // Skip risks without a selection

        risk.questions.forEach((question, questionIndex) => {
          if (
            question.type === "Single Select" ||
            question.type === "Multi Select"
          ) {
            // First check if the questionTree and options exist
            if (
              !question.questionTree ||
              !question.questionTree.question ||
              !question.questionTree.options
            ) {
              invalidQuestions.push(
                `Risk ${riskIndex + 1}, Question ${questionIndex + 1}`,
              );
              return;
            }

            // Count non-empty options
            const nonEmptyOptions = question.questionTree.options.filter(
              (opt) => opt && opt.trim() !== "",
            );

            // We need at least 2 non-empty options
            if (nonEmptyOptions.length < 2) {
              invalidQuestions.push(
                `Risk ${riskIndex + 1}, Question ${questionIndex + 1}`,
              );
            }
          }
        });
      });

      if (invalidQuestions.length > 0) {
        Swal.fire({
          toast: true,
          icon: "warning",
          title: "Missing question options",
          text: `Please add at least two options for: ${invalidQuestions.join(", ")}`,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            const popup = Swal.getPopup();

            popup.addEventListener("mouseenter", () => {
              Swal.stopTimer();
            });

            popup.addEventListener("mouseleave", () => {
              Swal.resumeTimer();
            });
          },
        });

        setSubmitting(false);
        dispatch(hideOverlay());
        return;
      }

      // Clean up questions - remove empty ones before validation
      processedValues.risks.forEach((risk) => {
        risk.questions = risk.questions.filter((question) => {
          // Keep only questions with type AND either text or questionTree data
          if (!question.type) return false;

          if (question.type === "Text" || question.type === "File") {
            return question.text && question.text.trim() !== "";
          }

          if (
            question.type === "Single Select" ||
            question.type === "Multi Select"
          ) {
            return (
              question.questionTree &&
              question.questionTree.question &&
              question.questionTree.question.trim() !== "" &&
              question.questionTree.options &&
              question.questionTree.options.length > 0
            );
          }

          return false;
        });
      });

      // Filter out risks without questions or without risk selection
      processedValues.risks = processedValues.risks.filter(
        (risk) => risk.risk && risk.questions.length > 0,
      );

      // If we removed all risks, show an error
      if (processedValues.risks.length === 0) {
        Swal.fire({
          toast: true,
          icon: "warning",
          title: "Each audit must have at least one risk with questions.",
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            const popup = Swal.getPopup();

            popup.addEventListener("mouseenter", () => {
              Swal.stopTimer();
            });

            popup.addEventListener("mouseleave", () => {
              Swal.resumeTimer();
            });
          },
        });

        setSubmitting(false);
        dispatch(hideOverlay());
        return; // Stop the function here
      }

      // Different logic for creating vs updating
      if (isEditingAudit && selectedAudit) {
        // EDITING EXISTING AUDIT
        const formattedData = formatAuditData(values);
        formattedData.uuid = selectedAudit.uuid; // Add UUID to formatted data

        const strippedSelectedAudit = {
          name: selectedAudit.name,
          frequency: selectedAudit.frequency,
          form: selectedAudit.form,
        };

        const strippedFormattedData = {
          name: formattedData.name,
          frequency: formattedData.frequency,
          form: formattedData.form,
        };

        const hasAuditChanges = !_.isEqual(
          strippedFormattedData,
          strippedSelectedAudit,
        );

        // Update the audit (PATCH request)
        if (hasAuditChanges) {
          // PATCH /audit
          await axiosInstance.patch(
            `/nocodb/audit?org_id=${orgId}`,
            formattedData,
            {
              headers: {
                "session-token": sessionToken,
              },
            },
          );
        }

        let scheduledTasks = [];

        // Check if startDate, endDate, and responsible are present
        if (values.startDate && values.endDate && values.responsible) {
          // Generate scheduled dates based on frequency
          const scheduledDates = generateDates(
            values.startDate,
            values.endDate,
            values.frequency,
          );

          // Prepare payload as an array of objects (each with a single date)
          const auditTasksPayload = scheduledDates.map((date) => ({
            responsible: values.responsible,
            date, // Ensure each object has only one date
          }));

          // POST to /nocodb/audit_tasks
          await axiosInstance.post(
            `/nocodb/audit_tasks?org_id=${orgId}&audit=${selectedAudit.uuid}`,
            auditTasksPayload,
            {
              headers: {
                "session-token": sessionToken,
              },
            },
          );

          // Create tasks array in the same format as used for existing audits
          scheduledTasks = scheduledDates.map((date) => ({
            audit_uuid: selectedAudit.uuid,
            responsible: values.responsible,
            date,
          }));
        }

        // Fetch updated organization data
        await dispatch(
          getOrganizationData(
            orgId,
            sessionToken,
            navigate,
            false,
            false,
            false,
            true,
            true,
            false,
          ),
        );

        // Update the selectedAudit with the new values
        setSelectedAudit({
          ...selectedAudit,
          ...formattedData,
          startDate: values.startDate,
          endDate: values.endDate,
          responsible: values.responsible,
          tasks:
            scheduledTasks.length > 0 ? scheduledTasks : selectedAudit.tasks,
          updated_at: new Date().toISOString(),
        });
      } else {
        // CREATING NEW AUDIT
        const postResponse = await axiosInstance.post(
          `/nocodb/audit?org_id=${orgId}`,
          {},
          {
            headers: {
              "session-token": sessionToken,
            },
          },
        );

        const auditUUID = postResponse.data; // The received UUID string

        if (!auditUUID) {
          throw new Error("Failed to get UUID from POST response");
        }

        // Formatted data for PATCH request
        const formattedData = formatAuditData(values);
        formattedData.uuid = auditUUID; // Add UUID to formatted data

        // Update the audit (PATCH request)
        await axiosInstance.patch(
          `/nocodb/audit?org_id=${orgId}`,
          formattedData,
          {
            headers: {
              "session-token": sessionToken,
            },
          },
        );

        let scheduledTasks = [];
        // Check if startDate, endDate, and responsible are present
        if (values.startDate && values.endDate && values.responsible) {
          // Generate scheduled dates based on frequency
          const scheduledDates = generateDates(
            values.startDate,
            values.endDate,
            values.frequency,
          );

          // Prepare payload as an array of objects (each with a single date)
          const auditTasksPayload = scheduledDates.map((date) => ({
            responsible: values.responsible,
            date, // Ensure each object has only one date
          }));

          // POST to /nocodb/audit_tasks
          await axiosInstance.post(
            `/nocodb/audit_tasks?org_id=${orgId}&audit=${auditUUID}`,
            auditTasksPayload,
            {
              headers: {
                "session-token": sessionToken,
              },
            },
          );

          // Create tasks array in the same format as used for existing audits
          scheduledTasks = scheduledDates.map((date) => ({
            audit_uuid: auditUUID,
            responsible: values.responsible,
            date,
          }));
        }

        // Fetch updated organization data
        await dispatch(
          getOrganizationData(
            orgId,
            sessionToken,
            navigate,
            false,
            false,
            false,
            true,
            true,
            false,
          ),
        );

        // Create startDate and endDate from tasks
        const taskDates =
          scheduledTasks.length > 0
            ? scheduledTasks
                .map((task) => new Date(task.date))
                .sort((a, b) => a - b)
            : [];

        const startDate = taskDates.length > 0 ? taskDates[0] : null;
        const endDate =
          taskDates.length > 0 ? taskDates[taskDates.length - 1] : null;

        // Select the newly created audit with task information
        const newAudit = {
          uuid: auditUUID,
          ...formattedData,
          startDate,
          endDate,
          responsible: values.responsible,
          tasks: scheduledTasks,
          updated_at: new Date().toISOString(),
        };

        setSelectedAudit(newAudit);
        setIsAddingAudit(false);
        setIsEditingAudit(true);
      }

      setDateRangeWarning(null);
    } catch (error) {
      console.error("Error while submitting audit:", error);
      Swal.fire({
        toast: true,
        icon: "error",
        title: `Error while submitting audit`,
        text: `Could not save changes. Please try again.`,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          const popup = Swal.getPopup();

          popup.addEventListener("mouseenter", () => {
            Swal.stopTimer();
          });

          popup.addEventListener("mouseleave", () => {
            Swal.resumeTimer();
          });
        },
      });
    } finally {
      setSubmitting(false);
      dispatch(hideOverlay());
    }
  };

  const handleArchiveAudit = async (auditUUID) => {
    if (!auditUUID) return;

    dispatch(showOverlay());

    try {
      await axiosInstance.patch(
        `/nocodb/audit?org_id=${orgId}`,
        { uuid: auditUUID, archived: true },
        {
          headers: {
            "session-token": sessionToken,
          },
        },
      );

      // Fetch updated data
      await dispatch(
        getOrganizationData(
          orgId,
          sessionToken,
          navigate,
          false,
          false,
          false,
          true,
          true,
          false,
        ),
      );

      setShowArchiveConfirmation(false);
      setSelectedAudit(null);
      setIsEditingAudit(false);

      Swal.fire({
        toast: true,
        icon: "success",
        title: "Audit archived successfully",
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          const popup = Swal.getPopup();

          popup.addEventListener("mouseenter", () => {
            Swal.stopTimer();
          });

          popup.addEventListener("mouseleave", () => {
            Swal.resumeTimer();
          });
        },
      });
    } catch (error) {
      console.error("Error archiving audit:", error);
      Swal.fire({
        toast: true,
        icon: "error",
        title: "Failed to archive audit",
        text: "Please try again.",
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          const popup = Swal.getPopup();

          popup.addEventListener("mouseenter", () => {
            Swal.stopTimer();
          });

          popup.addEventListener("mouseleave", () => {
            Swal.resumeTimer();
          });
        },
      });
    } finally {
      dispatch(hideOverlay());
    }
  };

  // Function to check for date range overlaps and set warnings
  const handleDateRangeChange = (update, auditUUID, setFieldValue) => {
    // Ensure dates are stored with a consistent time to avoid timezone issues
    let startDate = null;
    let endDate = null;

    if (update[0]) {
      startDate = new Date(update[0]);
      startDate.setHours(12, 0, 0, 0);
      setFieldValue("startDate", startDate);
    } else {
      setFieldValue("startDate", null);
    }

    if (update[1]) {
      endDate = new Date(update[1]);
      endDate.setHours(12, 0, 0, 0);
      setFieldValue("endDate", endDate);
    } else {
      setFieldValue("endDate", null);
    }

    // Scroll to the schedule section after setting new dates
    setTimeout(() => {
      if (scheduleRef.current) {
        scheduleRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 100);

    // Check for overlap only if both dates are set and we're editing an existing audit
    if (startDate && endDate && auditUUID && isEditingAudit) {
      const hasOverlap = checkDateRangeOverlap(startDate, endDate, auditUUID);
      if (hasOverlap) {
        setDateRangeWarning(
          "Warning: You already have scheduled audit tasks in this period.",
        );
      } else {
        setDateRangeWarning(null);
      }
    } else {
      setDateRangeWarning(null);
    }
  };

  const handleDeleteRisk = (riskIndex, values, setFieldValue) => {
    setRiskToDelete(riskIndex);
    setShowRiskDeleteWarning(true);
  };

  const handleConfirmRiskDelete = (values, setFieldValue) => {
    if (riskToDelete !== null) {
      const updatedRisks = [...values.risks];
      updatedRisks.splice(riskToDelete, 1);
      setFieldValue("risks", updatedRisks);
      setShowRiskDeleteWarning(false);
      setRiskToDelete(null);
    }
  };

  return (
    <MainLayout floatingBarCollapsedByDefault={true}>
      <div className={styles.column}>
        <div className={styles.breadcrumbsRow}>
          <Breadcrumbs data={currentModule} />
          {!isAddingAudit && (
            <Button
              type="button"
              text="+ Add a new audit"
              className={styles.addNewAuditButton}
              onClick={handleNewAuditClick}
            />
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.leftSide}>
            <div className={styles.filters}>
              <Input
                type="text"
                placeholder="Search an audit"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className={styles.input}
              />
              <div className={styles.sortWrapper}>
                <div
                  className={
                    sortType === "updated"
                      ? styles.activeSort
                      : styles.inactiveSort
                  }
                  onClick={() => handleSort("updated")}
                >
                  {sortType === "updated" && (
                    <img
                      src={
                        sortDirection === "asc" ? AscendingIcon : DescendingIcon
                      }
                      alt="Sort icon"
                      className={styles.sortIcon}
                    />
                  )}
                  <span>Last updated</span>
                </div>
                <div
                  className={
                    sortType === "end" ? styles.activeSort : styles.inactiveSort
                  }
                  onClick={() => handleSort("end")}
                >
                  {sortType === "end" && (
                    <img
                      src={
                        sortDirection === "asc" ? AscendingIcon : DescendingIcon
                      }
                      alt="Sort icon"
                      className={styles.sortIcon}
                    />
                  )}
                  <span> Last scheduled date</span>
                </div>
                <div
                  className={
                    sortType === "name"
                      ? styles.activeSort
                      : styles.inactiveSort
                  }
                  onClick={() => handleSort("name")}
                >
                  {sortType === "name" && (
                    <img
                      src={
                        sortDirection === "asc" ? AscendingIcon : DescendingIcon
                      }
                      alt="Sort icon"
                      className={styles.sortIcon}
                    />
                  )}
                  <span>Name</span>
                </div>
              </div>
            </div>
            {sortedAudits.length > 0 ? (
              sortedAudits.map((audit) => (
                <AuditCard
                  key={audit.uuid}
                  title={audit.name || "Unnamed Audit"}
                  dateRange={getAuditDateRange(audit.uuid)}
                  frequency={audit.frequency || "N/A"}
                  lastUpdated={audit.updated_at}
                  onClick={() => handleAuditClick(audit)}
                  isSelected={selectedAudit?.uuid === audit.uuid}
                />
              ))
            ) : (
              <p>No audits available.</p>
            )}
          </div>
          <div className={styles.rightSide}>
            {isAddingAudit || isEditingAudit ? (
              <div className={styles.auditForm}>
                <Formik
                  innerRef={formikRef}
                  enableReinitialize
                  initialValues={
                    isEditingAudit && selectedAudit
                      ? formatAuditToFormik(selectedAudit)
                      : {
                          name: "",
                          frequency: "",
                          risks: [{ id: 1, risk: "", questions: [] }],
                          startDate: null,
                          endDate: null,
                          responsible: "",
                        }
                  }
                  validationSchema={Yup.object({
                    name: Yup.string().required("Name is required"),
                    frequency: Yup.string().required("Frequency is required"),
                    responsible: Yup.string().when(["startDate", "endDate"], {
                      is: (startDate, endDate) => startDate && endDate,
                      then: (schema) =>
                        schema.required("Responsible is required"),
                      otherwise: (schema) => schema,
                    }),
                  })}
                  onSubmit={handleSubmit}
                >
                  {({ values, setFieldValue, resetForm, initialValues }) => {
                    const handleConfirmDelete = () => {
                      if (questionToDelete) {
                        const { riskIndex, questionIndex } = questionToDelete;
                        const updatedQuestions = [
                          ...values.risks[riskIndex].questions,
                        ];
                        updatedQuestions.splice(questionIndex, 1);
                        setFieldValue(
                          `risks[${riskIndex}].questions`,
                          updatedQuestions,
                        );
                        setShowDeleteWarning(false);
                        setQuestionToDelete(null);
                      }
                    };

                    const scheduledTaskCount =
                      values.startDate && values.endDate && values.frequency
                        ? generateDates(
                            values.startDate,
                            values.endDate,
                            values.frequency,
                          ).length
                        : 0;

                    return (
                      <div className={styles.formContent}>
                        <Form>
                          <CloseIcon
                            className={styles.closeButton}
                            onClick={() =>
                              handleCloseAuditForm(
                                resetForm,
                                values,
                                initialValues,
                              )
                            }
                          />
                          <div className={styles.formHeader}>
                            <div className={styles.name}>
                              <label>Name</label>
                              <Field
                                name="name"
                                component={Input}
                                placeholder="Enter an audit name"
                              />
                            </div>
                            <div className={styles.frequency}>
                              <label>Frequency</label>
                              <Field
                                name="frequency"
                                component={Select}
                                text="Select frequency"
                                options={frequencyOptions}
                                isOverlay={true}
                              />
                            </div>
                            <div className={styles.buttonGroup}>
                              {/* Display last updated date if editing an audit */}
                              {isEditingAudit && selectedAudit?.updated_at && (
                                <div className={styles.lastUpdated}>
                                  <label>Last updated:</label>
                                  <div>
                                    {new Date(
                                      selectedAudit.updated_at,
                                    ).toLocaleDateString("en-GB", {
                                      day: "numeric",
                                      month: "short",
                                      year: "2-digit",
                                    })}
                                  </div>
                                </div>
                              )}
                              <Button
                                text={isEditingAudit ? "Update" : "Create"}
                                type="submit"
                                disabled={
                                  isEditingAudit &&
                                  (loading ||
                                    !values.name ||
                                    !values.frequency ||
                                    values.risks.length === 0 || // Ensure at least one risk exists
                                    values.risks.every((risk) => !risk.risk) ||
                                    _.isEqual(values, initialValues))
                                }
                                className={classNames(styles.updateButton, {
                                  [styles.disabled]:
                                    loading ||
                                    !values.name ||
                                    !values.frequency ||
                                    values.risks.length === 0 || // Ensure at least one risk exists
                                    values.risks.every((risk) => !risk.risk) ||
                                    _.isEqual(values, initialValues),
                                })}
                                onClick={() => {
                                  if (!isEditingAudit) {
                                    // Apply extra validation logic only when creating a new audit
                                    formikRef.current?.setTouched({
                                      name: true,
                                      frequency: true, // Ensure Select field is marked as touched
                                    });
                                    formikRef.current?.validateForm();
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <CollapsibleCard
                            title="Risks"
                            defaultOpen={isAddingAudit}
                            buttonText="+ Add new risk"
                            onButtonClick={() => {
                              setFieldValue("risks", [
                                ...values.risks,
                                {
                                  id: values.risks.length + 1,
                                  risk: "",
                                  questions: [],
                                },
                              ]);

                              // Wait for the DOM to update, then scroll to the last risk
                              setTimeout(() => {
                                const lastRiskIndex = values.risks.length;
                                if (riskRefs.current[lastRiskIndex]) {
                                  riskRefs.current[
                                    lastRiskIndex
                                  ].scrollIntoView({
                                    behavior: "smooth",
                                    block: "center",
                                  });
                                }
                              }, 100);
                            }}
                          >
                            {values.risks.map((risk, riskIndex) => (
                              <div
                                key={riskIndex}
                                className={styles.riskContainer}
                                ref={(el) => (riskRefs.current[riskIndex] = el)}
                              >
                                <div className={styles.riskSelectWrapper}>
                                  <div className={styles.riskFieldContainer}>
                                    <Field
                                      key={risk.id}
                                      name={`risks[${riskIndex}].risk`}
                                      component={CascadingDropdown}
                                      text={
                                        risk.risk ? risk.risk : "Select risk"
                                      }
                                      label={`Risk ${riskIndex + 1}`}
                                      options={chapterOptions}
                                      suboptions={riskOptions}
                                      selectedValues={values.risks.map(
                                        (r) => r.risk,
                                      )} // Pass selected risks
                                      disableSelected={true}
                                    />
                                    {values.risks.length > 1 && (
                                      <button
                                        type="button"
                                        className={styles.deleteRiskButton}
                                        onClick={() =>
                                          handleDeleteRisk(riskIndex)
                                        }
                                      >
                                        Delete Risk
                                      </button>
                                    )}
                                  </div>
                                  <Button
                                    type="button"
                                    text="+ Add new question"
                                    className={styles.addNewQuestion}
                                    onClick={() =>
                                      handleAddQuestion(
                                        riskIndex,
                                        values,
                                        setFieldValue,
                                      )
                                    }
                                  />
                                </div>
                                {risk.questions.length > 0 &&
                                  risk.questions.map(
                                    (question, questionIndex) => (
                                      <div
                                        key={questionIndex}
                                        className={styles.questionContainer}
                                        ref={(el) =>
                                          (questionRefs.current[
                                            `${riskIndex}-${questionIndex}`
                                          ] = el)
                                        }
                                      >
                                        <div className={styles.select}>
                                          <label>Type of question</label>
                                          <Field
                                            name={`risks[${riskIndex}].questions[${questionIndex}].type`}
                                            component={Select}
                                            text="Select type"
                                            options={questionTypeOptions}
                                            isOverlay={true}
                                            onChange={(value) =>
                                              setFieldValue(
                                                `risks[${riskIndex}].questions[${questionIndex}].type`,
                                                value,
                                              )
                                            }
                                          />
                                        </div>
                                        {(values.risks[riskIndex].questions[
                                          questionIndex
                                        ].type === "Text" ||
                                          values.risks[riskIndex].questions[
                                            questionIndex
                                          ].type === "File") && (
                                          <div className={styles.textInput}>
                                            <label>{`Question ${questionIndex + 1}`}</label>
                                            <Field
                                              name={`risks[${riskIndex}].questions[${questionIndex}].text`}
                                              component={Input}
                                              placeholder="Write a new question"
                                            />
                                          </div>
                                        )}
                                        {(values.risks[riskIndex].questions[
                                          questionIndex
                                        ].type === "Single Select" ||
                                          values.risks[riskIndex].questions[
                                            questionIndex
                                          ].type === "Multi Select") && (
                                          <Field
                                            name={`risks[${riskIndex}].questions[${questionIndex}].questionTree`}
                                            component={QuestionTree}
                                            questionIndex={questionIndex}
                                          />
                                        )}
                                        <DeleteIcon
                                          className={styles.removeQuestion}
                                          onClick={() => {
                                            setQuestionToDelete({
                                              riskIndex,
                                              questionIndex,
                                            });
                                            setShowDeleteWarning(true);
                                          }}
                                        />
                                      </div>
                                    ),
                                  )}
                              </div>
                            ))}
                          </CollapsibleCard>
                          <CollapsibleCard title="Schedule" defaultOpen={true}>
                            <div
                              className={styles.scheduleWrapper}
                              ref={scheduleRef}
                            >
                              <div className={styles.dateRangeContainer}>
                                <label className={styles.label}>
                                  Date range
                                </label>
                                <DateRangeFilter
                                  dateRange={[values.startDate, values.endDate]}
                                  handleDateRangeChange={(update) =>
                                    handleDateRangeChange(
                                      update,
                                      selectedAudit?.uuid,
                                      setFieldValue,
                                    )
                                  }
                                  isClearable
                                />
                                {dateRangeWarning && (
                                  <div className={styles.dateRangeWarning}>
                                    {dateRangeWarning}
                                  </div>
                                )}
                              </div>
                              <div className={styles.responsible}>
                                <label className={styles.label}>
                                  Responsible
                                </label>
                                <Field
                                  name="responsible"
                                  component={Input}
                                  placeholder="you@example.com"
                                />
                              </div>
                            </div>
                            <AuditCalendar
                              startDate={values.startDate}
                              endDate={values.endDate}
                              frequency={values.frequency}
                            />
                            <div>
                              {scheduledTaskCount > 0 && (
                                <span>
                                  This schedule will generate{" "}
                                  <strong>{scheduledTaskCount}</strong> audit
                                  task{scheduledTaskCount > 1 ? "s" : ""}.
                                </span>
                              )}
                            </div>
                          </CollapsibleCard>
                          {isEditingAudit && (
                            <div className={styles.archiveContainer}>
                              <Button
                                type="button"
                                text="Archive audit"
                                icon={<ArchiveIcon />}
                                className={styles.archiveButton}
                                onClick={() => setShowArchiveConfirmation(true)}
                              />
                            </div>
                          )}
                        </Form>
                        {showConfirmationPopup && (
                          <ConfirmationPopup
                            message="Your changes won’t be saved if you close this window."
                            illustration={WarningIllustration}
                            confirmText="Close Without Saving"
                            onConfirm={() => handleConfirmClose(resetForm)}
                            onCancel={handleCancel}
                          />
                        )}
                        {showNewAuditConfirmation && (
                          <ConfirmationPopup
                            message="Your changes won’t be saved if you start a new audit. Do you want to proceed?"
                            illustration={WarningIllustration}
                            confirmText="Start New Audit"
                            onConfirm={() => {
                              if (formikRef.current) {
                                formikRef.current.resetForm();
                              }
                              setIsAddingAudit(true);
                              setIsEditingAudit(false);
                              setSelectedAudit(null);
                              setShowNewAuditConfirmation(false);
                            }}
                            onCancel={() => setShowNewAuditConfirmation(false)}
                          />
                        )}
                        {showDeleteWarning && (
                          <div className={styles.rightSideOverlay}>
                            <SmallConfirmationPopup
                              message="Are you sure you want to delete this question?"
                              confirmText="Delete"
                              cancelText="Cancel"
                              onConfirm={handleConfirmDelete}
                              onCancel={() => setShowDeleteWarning(false)}
                            />
                          </div>
                        )}
                        {showArchiveConfirmation && (
                          <div className={styles.rightSideOverlay}>
                            <SmallConfirmationPopup
                              message="Are you sure you want to archive this audit? This action cannot be undone."
                              confirmText="Archive"
                              cancelText="Cancel"
                              onCancel={() => setShowArchiveConfirmation(false)}
                              onConfirm={() =>
                                handleArchiveAudit(selectedAudit.uuid)
                              }
                            />
                          </div>
                        )}
                        {showRiskDeleteWarning && (
                          <div className={styles.rightSideOverlay}>
                            <SmallConfirmationPopup
                              message="Are you sure you want to delete this risk? All related questions will be removed."
                              confirmText="Delete"
                              cancelText="Cancel"
                              onConfirm={() =>
                                handleConfirmRiskDelete(values, setFieldValue)
                              }
                              onCancel={() => setShowRiskDeleteWarning(false)}
                            />
                          </div>
                        )}
                      </div>
                    );
                  }}
                </Formik>
              </div>
            ) : (
              <div className={styles.explanation}>
                Click on “+ Add a new audit” to start the process of creating
                one,
                <br />
                or select an existing audit from the list on the left to edit or
                schedule it.
              </div>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default ManageAudits;
