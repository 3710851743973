import React from "react";
import styles from "./Card.module.scss";
import classNames from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import SecondaryButton from "../Button/SecondaryButton";
import ArrowIcon from "../../icons/right-arrow.svg";
import DefineIcon from "../../icons/define.svg";
import MeasureIcon from "../../icons/measure.svg";
import AnalyseIcon from "../../icons/analyse.svg";
import ImproveIcon from "../../icons/improve.svg";
import ControlIcon from "../../icons/control.svg";

const Card = ({ className, category, modules }) => {
  const { orgId } = useParams();
  const navigate = useNavigate();

  const getCategoryColorClassName = (category) => {
    switch (category) {
      case "define":
        return styles.borderDefine;
      case "analyse":
        return styles.borderAnalyse;
      case "measure":
        return styles.borderMeasure;
      case "improve":
        return styles.borderImprove;
      case "control":
        return styles.borderControl;
      default:
        return "";
    }
  };

  const getCategoryIcon = (category) => {
    switch (category) {
      case "define":
        return DefineIcon;
      case "measure":
        return MeasureIcon;
      case "analyse":
        return AnalyseIcon;
      case "improve":
        return ImproveIcon;
      case "control":
        return ControlIcon;
      default:
        return null;
    }
  };

  return (
    <div
      className={classNames(
        styles.card,
        getCategoryColorClassName(category),
        className,
      )}
    >
      <div className={styles.categoryText}>
        <img
          src={getCategoryIcon(category)}
          alt={`${category} icon`}
          className={styles.categoryIcon}
        />
        {category}
      </div>{" "}
      <div className={styles.content}>
        {modules.map((module, index) => (
          <SecondaryButton
            key={index}
            text={module.title}
            onClick={() => navigate(`/${orgId}${module.path}`)}
            className={styles.moduleItem}
            iconPosition="right"
            icon={ArrowIcon}
          />
        ))}
      </div>
    </div>
  );
};

export default Card;
