import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./HomePage.module.scss";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import { setLoadingContext } from "../../redux/loaderStore";
import { PAGE_LOADER } from "../../constants/loader";
import Card from "../../components/Card/Card";
import TreeLayout from "../../components/TreeLayout/TreeLayout";
import useFetchOrganizationData from "../../actions/useFetchOrganizationData";
import { useMediaQuery } from "@mui/material";

const HomePage = () => {
  const { orgId } = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:768px)");

  useFetchOrganizationData();

  const modules = useSelector((state) => state.user.modules);

  const [organizationModules, setOrganizationModules] = useState(null);

  useEffect(() => {
    // dispatch(setLoadingContext({ context: PAGE_LOADER, isLoading: true }));

    if (modules && modules[orgId]) {
      setOrganizationModules(modules[orgId]);
      // dispatch(setLoadingContext({ context: PAGE_LOADER, isLoading: false }));
    }
  }, [modules, orgId]);

  const filterModulesForNonMobile = (modules) => {
    // Exclude "New Finding" module for non-mobile
    return modules?.filter((module) => module.title !== "New Finding");
  };

  const homeModule = organizationModules?.find(
    (module) => module.title === "Home",
  );

  const newFindingModule = organizationModules?.find(
    (module) => module.title === "New Finding",
  );

  console.log(organizationModules, "modules");

  const filterModulesByCategory = (category) => {
    const filteredModules = isMobile
      ? organizationModules
      : filterModulesForNonMobile(organizationModules);

    return filteredModules
      ? filteredModules.filter(
          (module) => module.category?.toLowerCase() === category.toLowerCase(),
        )
      : [];
  };

  return (
    <MainLayout>
      <div className={styles.homePageWrapper}>
        {isMobile ? (
          // Show only "New Finding" for mobile users
          newFindingModule ? (
            <div className={styles.mobileCardWrapper}>
              <Card
                category="New finding"
                modules={[newFindingModule]} // Render only "New Finding" card
              />
            </div>
          ) : (
            <p>No modules available</p>
          )
        ) : (
          // Show the full view for non-mobile users
          homeModule && (
            <>
              <div className={styles.cardWrapper}>
                <Card
                  category="define"
                  modules={filterModulesByCategory("Define")}
                />
                <Card
                  category="measure"
                  modules={filterModulesByCategory("Measure")}
                />
                <Card
                  category="analyse"
                  modules={filterModulesByCategory("Analyse")}
                />
                <Card
                  category="improve"
                  modules={filterModulesByCategory("Improve")}
                />
                <Card
                  category="control"
                  modules={filterModulesByCategory("Control")}
                />
              </div>
              {!isMobile && <TreeLayout />}
            </>
          )
        )}
      </div>
    </MainLayout>
  );
};

export default HomePage;
