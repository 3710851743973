import React from "react";
import classNames from "classnames";
import styles from "./Input.module.scss";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";

const Input = ({
  className,
  icon,
  field,
  form,
  isClearable,
  onClear,
  ...props
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.inputWrapper}>
        <input
          {...field}
          {...props}
          className={classNames(styles.inputContainer, className, {
            [styles.errorBorder]:
              form?.errors[field.name] && form.touched[field.name],
          })}
        />
        {isClearable && props.value && (
          <button
            type="button"
            className={styles.clearButton}
            onClick={() => {
              onClear?.();
              if (props.onChange) {
                props.onChange({ target: { value: "" } }); // Ensure value is cleared
              }
            }}
          >
            <CloseIcon />
          </button>
        )}
      </div>
      {form?.errors[field.name] && form.touched[field.name] && (
        <div className={styles.error}>{form.errors[field.name]}</div>
      )}
    </div>
  );
};

export default Input;
