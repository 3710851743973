import React from "react";
import styles from "./Breadcrumbs.module.scss";
import RightArrow from "../../icons/arrow-right.svg";
import House from "../../icons/house.svg";
import classNames from "classnames";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { ReactComponent as QuestionMarkIcon } from "../../icons/circled-question-mark.svg";

const Breadcrumbs = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orgId } = useParams();

  const { meModeActive } = useSelector((state) => state.filters);

  const showQuestionMark = [
    "/findings",
    "/improvement-projects",
    "/audits",
  ].some((path) => location.pathname.endsWith(path));

  const getBadgeClassName = (category) => {
    switch (category) {
      case "define":
        return styles.badgeDefine;
      case "analyse":
        return styles.badgeAnalyse;
      case "measure":
        return styles.badgeMeasure;
      case "improve":
        return styles.badgeImprove;
      case "control":
        return styles.badgeControl;
      default:
        return styles.badgeGeneral;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.breadcrumbs}>
        <div className={styles.home} onClick={() => navigate(`/${orgId}`)}>
          <img src={House} alt="Home icon" className={styles.icon} />
          Home
        </div>
        <img src={RightArrow} alt="Arrow icon" className={styles.icon} />
        <div
          className={classNames(
            styles.badge,
            getBadgeClassName(data?.category?.toLowerCase()),
          )}
        >
          {data?.category || "General"}
        </div>
        <img src={RightArrow} alt="Arrow icon" className={styles.icon} />
        <div className={styles.title}>{data?.title}</div>
      </div>
      <div className={styles.meModeQuestionMark}>
        {meModeActive && showQuestionMark && (
          <>
            <QuestionMarkIcon
              alt="What is Me Mode?"
              data-tooltip-id="me-mode-tooltip"
              data-tooltip-content="Me Mode shows the data where you are mentioned (e.g., reporter, responsible, accountable, consulted, informed)."
            />
            <Tooltip
              id="me-mode-tooltip"
              place="left"
              className={styles.tooltip}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Breadcrumbs;
